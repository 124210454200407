import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import RaffleRating from '../misc/RaffleRating';
import ChangeDetails from './ChangeDetails';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const ProfilePage = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [raffles, setRaffles] = useState([]);
  const [showProfileOverlay, setShowProfileOverlay] = useState(false);
  const [profileImageSrc, setProfileImageSrc] = useState('/profile_placeholder.png'); // Default profile image
  const { username: currentUser } = useAuth();
  const [bio, setBio] = useState('');
  const [showBioOverlay, setShowBioOverlay] = useState(false);
  const [isChangeDetailsModalOpen, setIsChangeDetailsModalOpen] = useState(false);

  const openChangeDetailsModal = () => {
    setIsChangeDetailsModalOpen(true);
  };
  
  const isOwnProfile = username === currentUser;

  // Fetch user information
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${backendServerUrl}/api/user?username=${username}`);
        if (response.status === 200){
          setUser(response.data);

          if (response.data.profileImgUrl) {
            setProfileImageSrc(response.data.profileImgUrl);
          }
        }
       
      } catch (error) {
        console.error('An error occurred while fetching user:', error);
      }
    };

    fetchUser();
  }, [username]);

  // Fetch raffles related to user
  useEffect(() => {
    const fetchRaffles = async () => {
      if (!user) return;

      try {
        const response = await axios.get(`${backendServerUrl}/api/raffle?userId=${user._id}`);
        // console.log(`${backendServerUrl}/raffles?userId=${user._id}`);
        setRaffles(response.data);
      } catch (error) {
        console.error('An error occurred while fetching raffles:', error);
      }
    };

    fetchRaffles();
  }, [user]);

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const saveBio = async () => {
    try {
        const response = await axios.post(`${backendServerUrl}/api/user/setbio`, { bio }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });

        if (response.status === 200) {
            console.log('Bio saved:', bio);
        } else {
            console.error('Error saving bio:', response.data);
        }
    } catch (error) {
        console.error('Error saving bio:', error);
    }
};

  const setUserImage = async (image) => {
    const formData = new FormData();
    formData.append('file', image);  // Add the image file to FormData
  
    try {
      console.log("Uploading: ", image);
  
      const response = await axios.post(`${backendServerUrl}/api/user/setUserImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
  
      setProfileImageSrc(response.data.imageUrl); 

      setShowProfileOverlay(false);
  
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserImage(file);
  };

  return (
    <div className="container mx-auto mt-4 mb-4 py-10 px-5 max-w-screen-lg border rounded shadow-md bg-white">
      {user ? (
        <>
          <div className="flex flex-wrap md:flex-nowrap">
            {/* Profile Picture */}
            <div
              className="relative w-24 h-24 mx-auto md:mx-0"
              onMouseEnter={() => setShowProfileOverlay(true)}
              onMouseLeave={() => setShowProfileOverlay(false)}
            >
              <img
                src={profileImageSrc || "/profile_placeholder.png"}
                alt="Profile"
                className="rounded-full w-full h-full object-cover"
              />
              {(showProfileOverlay && isOwnProfile) && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                  <label
                    htmlFor="profile-image"
                    className="cursor-pointer text-white text-center"
                  >
                    Change photo
                  </label>
                </div>
              )}
              <input
                type="file"
                id="profile-image"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>
            
            {/* User Information */}
            <div className="w-full md:w-1/3 pl-4 mt-4 md:mt-0">
              <div className='text-xl font-bold'>{user.username}</div>
              <div className='mt-1'><RaffleRating rating={user.rating} /></div>
              {/* <div>Rating: <RaffleRating rating={4.75} /></div> */}
              {user.ratingCount > 0 ? (<div className='text'>from {user.ratingCount} reviews</div>) : (<div className='text'>No reviews yet</div>)}
            </div>
            
            {/* Bio */}
            <div className="w-full md:w-1/2 pl-4 mt-4 md:mt-0 md:ml-8 relative">
              {isOwnProfile ? (
                <>
                  <textarea
                    value={bio}
                    onChange={handleBioChange}
                    onBlur={saveBio}
                    placeholder="Your bio here..."
                    className="border rounded w-full py-2 px-3"
                  />
                  {(showBioOverlay && isOwnProfile) && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                      <label className="cursor-pointer text-white text-center">
                        Edit Bio
                      </label>
                    </div>
                  )}
                </>
              ) : (
                <div className="py-2 px-3 border rounded w-full text-gray-600">
                  {bio ? bio : "This user has no Bio."}
                </div>
              )}
            </div>
          </div>

          {/* Change my Details button */}
          {isOwnProfile && (
            <div className="mt-6">
              <button 
                className="py-2 px-4 bg-blue-500 text-white rounded"
                onClick={openChangeDetailsModal}
              >
                Change my Details
              </button>
            </div>
          )}

          {/* ChangeDetails modal */}
          {isChangeDetailsModalOpen && <ChangeDetails onClose={() => setIsChangeDetailsModalOpen(false)} />}

          {/* User Raffles */}
          <div className="mt-6">
            <h2 className="text-2xl mb-4">Raffles by {user.username}</h2>

            {raffles.length === 0 ? (
              <div className="italic text-gray-600">This User has no Raffles yet.</div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {raffles.map((raffle, index) => (
                  <Link to={`/raffles/${raffle._id}`} >
                    <div key={index} className="border rounded p-4 shadow-md hover:shadow-lg transition-shadow duration-300">
                      <h3 className="text-xl mb-2">{raffle.title}</h3>
                      <div className="text-gray-600">{raffle.state}</div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>

        </>
      ) : (
        <div className="flex justify-center items-center">
          <div className="spinner m-4"></div>
        </div>
      )}
    </div>
  );
  

}
  
  
  
  
  

export default ProfilePage;
