import React, { useState } from 'react';
import { useBasket } from './BasketContext';
import DeliveryAddressForm from './DeliveryAddressForm';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;
const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const CheckoutPage = () => {
  const { refreshUserData, userId } = useAuth();
  const { basketItems, totalAmount, clearBasket } = useBasket();
  const [deliveryAddr, setDeliveryAddr] = useState(null);
  const [billingAddr, setBillingAddr] = useState(null);
    const [copyBillingAddress, setCopyBillingAddress] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSaveAddressesClick = () => {
    try{
        // Call the update-address API with the deliveryAddr and billingAddr data
        const payload = {
            deliveryAddr,
            billingAddr
        };
        const response = axios.post(`${backendServerUrl}/api/user/update-address`, payload, { withCredentials: true });

        if (response.status !== 200) {
            console.error('Update address failed:', response.data.message);
            return; // Ensure you exit the function here
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Unable to update Addresses. Please try again.');
        // Handle the error as needed
    }
  };

  const createOrder = async (data, actions) => {
    try {
        const payload = {
            raffles: basketItems,
        };
        const response = await axios.post(`${backendServerUrl}/api/paypal/create-order`, payload, { withCredentials: true });

        if (response.status !== 200) {
            console.error('Create order failed:', response.data.message);
            navigate('/raffle-error');
            return; // Ensure you exit the function here
        }

        return response.data.orderID;

    } catch (error) {
        console.error('Error during create order:', error);
        alert('An error occurred while creating the order. Please try again.'); // Display a generic error message to the user
        navigate('/raffle-error');
    }
  };



  const onApprove = async (data, actions) => {
    const orderID = data.orderID;
    const response = await axios.post(`${backendServerUrl}/api/paypal/capture-payment`, { orderID }, { withCredentials: true });
    const { message } = response.data;
    if (response.status === 200) {
      clearBasket();
      refreshUserData();
      navigate('/raffle-success');
    } else {
      console.error('Payment capture failed:', message);
      navigate('/raffle-error');
    }
  };

  const handleBalancePayment = async () => {
    try {
        const response = await axios.post(
            `${backendServerUrl}/api/raffle/enter`,
            { raffles: basketItems, paymentMethod: 'balance' },
            { withCredentials: true }
        );

        if (response.status === 200) {
            clearBasket();
            refreshUserData();
            navigate('/raffle-success'); // Redirect to a success page
        }
    } catch (error) {
        console.error('An error occurred while entering the raffles: ', error);
        
        if (error.response && error.response.status === 401) {
            sessionStorage.setItem('redirectAfterLogin', location.pathname);
            navigate('/login'); // Redirect to login if unauthorized
        } else {
            if (error.response && error.response.data){
                alert(error.response.data);
            } else {
                alert('An error occurred while entering the raffles.'); // Display a generic error message otherwise
            }
        }
    }
  };

  const initialOptions = {
      'client-id': paypalClientId,
      'currency': 'GBP',
      'enable-funding': 'paypal,venmo,paylater,card',
      'data-sdk-integration-source': 'integrationbuilder_sc',
  };


  return (
      <div className="bg-prize-white text-prize-blue min-h-screen p-8">
          <h1 className="text-3xl font-semibold mb-6">Checkout</h1>
          <div className="mb-8">
              <h2 className="text-2xl mb-4">Basket Items</h2>
              {basketItems.map((item, index) => (
                  <div key={index}>
                      <p>{item.title} x {item.quantity} - £{(item.quantity * item.price).toFixed(2)}</p>
                  </div>
              ))}
              <p>Total: £{totalAmount.toFixed(2)}</p>
          </div>
          
          <DeliveryAddressForm setDeliveryAddress={setDeliveryAddr} addressType="Delivery" />
          {/* checkbox to copy delivery address to billing address */}
        <div className="mb-2">
            <input 
                id="copyBillingAddress"
                type="checkbox"
                className="mr-2"
                checked={copyBillingAddress}
                onChange={() => setCopyBillingAddress(!copyBillingAddress)}
            />
            <label htmlFor="copyBillingAddress">Same as billing address</label>
            </div>
          {/* if checkbox is checked, hide the Billing address form */}
          {!copyBillingAddress && ( <DeliveryAddressForm setBillingAddress={setBillingAddr} addressType="Billing" /> )}

        <button 
            type="submit" 
            className="bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleSaveAddressesClick}
        >
            Save Addresses
        </button>
            {totalAmount > 0 ? (
            <div className="flex flex-col items-center w-full p-4">
                <h2 className="text-2xl mb-4">Payment Options</h2>
                <button 
                    onClick={() => handleBalancePayment()} 
                    className="bg-blue-500 text-white py-2 px-4 rounded mb-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                >
                    Use Balance
                </button>

                <PayPalScriptProvider options={initialOptions}>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                    <PayPalButtons 
                        style={{ layout: 'vertical' }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
                    </div>
                </PayPalScriptProvider>
            </div> 
          ) : (
            <div className="flex flex-col items-center w-full p-4">
                <h2 className="text-2xl mb-4">Confirm Entry</h2>
                <button 
                    onClick={() => handleBalancePayment()} 
                    className="bg-blue-500 text-white py-2 px-4 rounded mb-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                >
                    Confirm
                </button>
            </div>
          )}
      </div>
    );
  };

export default CheckoutPage;
