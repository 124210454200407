import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import { AuthProvider } from './components/auth/AuthContext';
import CreateRaffle from './components/raffles/CreateRaffle';
import RaffleDetails from './components/raffles/RaffleDetails';
import { HomePage } from './components/pages/HomePage';
import NavigationBar from './components/layout/NavigationBar';
import Footer from './components/layout/Footer';
import SearchResults from './components/pages/SearchResults';
import axios from 'axios';
import MyRaffles from './components/raffles/MyRaffles';
import RaffleSuccess from './components/raffles/RaffleSuccess'; 
import RaffleError from './components/raffles/RaffleError';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfService from './components/pages/TermsOfService';
import Faq from './components/pages/Faq';
//import PayPalScriptLoader from './components/payment/PayPalScriptLoader';
//import ErrorBoundary from './components/misc/ErrorBoundary';
import OptionSelectionPage from './components/raffles/OptionSelectionPage';
import BasketPage from './components/payment/BasketPage';
import CheckoutPage from './components/payment/CheckoutPage';
import { BasketProvider } from './components/payment/BasketContext';
import ProfilePage from './components/user/ProfilePage';
import MyEntries from './components/raffles/MyEntries';
import VerifyEmail from './components/user/VerifyEmail';
import EntryDetailsPage from './components/raffles/EntryDetails';
import ComingSoon from './components/temp/ComingSoon';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import DevelopmentModal from './components/temp/DevelopmentModal';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPaypalReady, setIsPaypalReady] = useState(false);
  const [isDevModalOpen, setIsDevModalOpen] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      setIsDevModalOpen(true);
    }
  }, []);

  const handleCloseDevModal = () => {
    setIsDevModalOpen(false);
    // Additional logic after accepting the terms
  };

  // const handlePaypalReady = () => {
  //   setIsPaypalReady(true);
  // };

  useEffect(() => {
    const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

    axios.get(`${backendServerUrl}/api/user/current`, { withCredentials: true })
      .then(res => {
        if (res.data.loggedIn) {
          setIsLoggedIn(true);
          //console.log('State logged in') // Debug
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }, []);

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'development') {
      document.title = 'Prize Trust - Development';
    } else {
      document.title = 'Prize Trust';
    }
  }, []);

  // Check if the COMING_SOON environment variable is set
  const isComingSoon = process.env.REACT_APP_COMING_SOON === 'true';

  return (
    <AuthProvider>
      <BasketProvider>
      <DevelopmentModal isOpen={isDevModalOpen} onClose={handleCloseDevModal} />
        <div className="bg-prize-white text-prize-blue min-h-screen">
          <main>
            {isComingSoon ? (
                // If COMING_SOON is true, render the Coming Soon page
                <ComingSoon />
              ) : (
                <Router>
                <NavigationBar/>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/create-raffle" element={<CreateRaffle />} />           
                    <Route path="/raffles/:id" element={<RaffleDetails isPaypalReady={isPaypalReady}/>} />
                    <Route path="/my-raffles" element={ <MyRaffles /> } />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/raffle-success" element={<RaffleSuccess />} />
                    <Route path="/raffle-error" element={<RaffleError />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/option-selection" element={<OptionSelectionPage />} />
                    <Route path="/basket" element={<BasketPage />} />
                    <Route path="/checkout" element={<CheckoutPage />} />
                    <Route path="/profile/:username" element={<ProfilePage />} />
                    <Route path="/my-entries" element={ <MyEntries /> } />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/entry-details/:raffleId" element={<EntryDetailsPage />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                  </Routes>
                <Footer />
                </Router>
              )}
          </main>
        </div>
      </BasketProvider>
    </AuthProvider>
  );
}

export default App;
