import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [isHandlingClick, setIsHandlingClick] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsHandlingClick(true);

    axios
      .post(`${backendServerUrl}/api/user/requestpasswordreset`, { emailAddress })
      .then((response) => {
        setMessage("If an account with that email address exists, we have sent a password reset link to it.");
        setIsHandlingClick(false);
        // Optionally navigate to login or another page after a short delay
        setTimeout(() => navigate("/login"), 5000);
      })
      .catch((error) => {
        setIsHandlingClick(false);
        console.error(error);
        setMessage("An error occurred while requesting a password reset.");
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
        <p className="text-center mb-4">Enter the email address associated with your account.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-2 mb-4 border rounded"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <div className="relative z-10">
            <button className="w-full bg-blue-500 text-white p-2 rounded" type="submit">
              Send Reset Link
            </button>
            {isHandlingClick && (
              <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
                Loading...
              </div>
            )}
          </div>
        </form>
        {message && (
          <div className="mt-4 text-center text-sm text-blue-600">
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
