import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const ResetPassword = () => {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [tokenValid, setTokenValid] = useState(false);
    const [message, setMessage] = useState('');
    const [isHandlingClick, setIsHandlingClick] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');

        axios.get(`${backendServerUrl}/api/user/validateresettoken?token=${token}`)
            .then(response => {
                setTokenValid(true);
                setMessage('Please enter your new password.');
            })
            .catch(error => {
                setTokenValid(false);
                setMessage('Invalid or expired token.');
            });
    }, []);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        const validationErrors = validatePassword(newPassword);

        setPasswordErrors(validationErrors);
        setFormData({
            ...formData,
            password: newPassword,
        });

        // Validate confirm password as well
        setConfirmPasswordError(newPassword !== formData.confirmPassword ? 'Passwords must match.' : '');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Additional validation for confirm password
        if (name === 'confirmPassword') {
            setConfirmPasswordError(value !== formData.password ? 'Passwords must match.' : '');
        }
    };

    const validatePassword = (password) => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\-_=+\/\\\[\]`~£€]/.test(password);
        const hasMinLength = password.length >= 8;
      
        const errorMsgs = [];
      
        if (!hasUppercase) {
          errorMsgs.push('Must contain at least one uppercase letter.');
        }
        if (!hasLowercase) {
          errorMsgs.push('Must contain at least one lowercase letter.');
        }
        if (!hasNumber) {
          errorMsgs.push('Must contain at least one number.');
        }
        if (!hasSpecialChar) {
          errorMsgs.push('Must contain at least one special character.');
        }
        if (!hasMinLength) {
          errorMsgs.push('Must be at least 8 characters long.');
        }
      
        return errorMsgs;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsHandlingClick(true);

        // Check for frontend validation errors
        const hasErrors = passwordErrors.length > 0 || confirmPasswordError;

        if (hasErrors) {
            alert("Please fix the errors before proceeding.");
            setIsHandlingClick(false);
            return;
        }

        const token = new URLSearchParams(window.location.search).get('token');

        axios.post(`${backendServerUrl}/api/user/resetpassword`, { token, password: formData.password })
            .then(response => {
                alert("Password reset successfully");
                setIsHandlingClick(false);
                navigate('/login');
            })
            .catch(error => {
                setIsHandlingClick(false);
                console.error(error);
                alert("An error occurred while resetting the password.");
            });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h2 className="text-2xl text-center font-bold mb-4">
                    Reset Password
                </h2>
                <p className="text-center mb-4">{message}</p>
                {tokenValid && (
                    <form onSubmit={handleSubmit}>
                        <input
                            type="password"
                            name="password"
                            placeholder="New Password"
                            className="w-full p-2 mb-4 border rounded"
                            onChange={handlePasswordChange}
                        />
                        {passwordErrors.length > 0 && (
                            <div className="error-messages text-red-500 mb-4">
                                <ul className="list-disc list-inside">
                                    {passwordErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm New Password"
                            className="w-full p-2 mb-4 border rounded"
                            onChange={handleChange}
                        />
                        {confirmPasswordError && (
                            <div className="error-messages text-red-500 mb-4">
                                {confirmPasswordError}
                            </div>
                        )}
                        <div className="relative">
                            <button className="w-full bg-blue-500 text-white p-2 rounded" type="submit">
                                Reset Password
                            </button>
                            {isHandlingClick && (
                                <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
                                    Loading...
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}

export default ResetPassword;
