import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../../styles/customStyles.css';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

export const HomePage = () => {
  const [featuredRaffles, setFeaturedRaffles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const categoriesFetchedRef = useRef(false);
  const isFetchingCategoriesRef = useRef(false);


  useEffect(() => {
    // Fetch raffles and categories when the component mounts
    const fetchCategories = async () => {
      if (isFetchingCategoriesRef.current) {
        return; // Exit early if a fetch is currently in progress
      }
  
      isFetchingCategoriesRef.current = true; // Mark the fetch as in progress
    
      try {
        const response = await axios.get(`${backendServerUrl}/api/raffle/categories`, { withCredentials: true });
        if (response.data && Array.isArray(response.data.categories)) {
          const rafflePromises = response.data.categories.map(category => fetchRafflesForCategory(category.name));
    
          const allRaffles = await Promise.all(rafflePromises);
    
          const updatedCategories = response.data.categories.map((category, index) => ({
            ...category,
            raffles: allRaffles[index],
          }));
          
          setCategories(updatedCategories);
          categoriesFetchedRef.current = true;
        }
      } catch (error) {
        console.error('Could not fetch categories:', error);
      } finally {
        isFetchingCategoriesRef.current = false; // Reset the fetch status
      }
    };

    fetchFeaturedRaffles();
    fetchCategories();
  }, []);

  const fetchFeaturedRaffles = async () => {
    try {
      const response = await axios.get(`${backendServerUrl}/api/raffle?state=Active`, { withCredentials: true });
      setFeaturedRaffles(response.data);
    } catch (error) {
      console.error('Could not fetch featured raffles:', error);
    }
  };

  const fetchRafflesForCategory = async (categoryName) => {
    try {
      const encodedCategoryName = encodeURIComponent(categoryName);
      const response = await axios.get(`${backendServerUrl}/api/raffle?state=Active&category=${encodedCategoryName}`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error(`Could not fetch raffles for category ${categoryName}:`, error);
      return [];
    }
  };

  const settings = {
    slidesToShow: 6, // default number of slides to show on large screens
    responsive: [
      {
        breakpoint: 1024, // breakpoint for large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // breakpoint for tablet screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // breakpoint for mobile screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div className="p-8">
    
      <p className="text-xl mb-8">Host Raffles. Enter Raffles. Win Raffles.</p>

      {/* Featured Raffles */}
      <div className="mb-8 bg-gray-100 p-4 rounded-md">
        <h2 className="text-2xl font-semibold mb-4">All Raffles (will be Featured Raffles when Live)</h2>
        <Slider
          {...settings} // pass in the settings object defined above
          className='mx-4 flex-nowrap'
          dots={false}
          infinite={true}
          speed={500}
          //slidesToShow={3}
          slidesToScroll={1}
          arrows={true}
          initialSlide={0}
          centerMode={false}
          
        >
          {featuredRaffles.map((raffle) => (
            <div key={raffle._id}>
              <Link 
                to={`/raffles/${raffle._id}`} 
                className="block p-4 bg-white rounded-lg shadow-md w-64 mb-2">
                <img src={raffle.imageUrls[0]} alt={raffle.title} className="w-64 h-40 object-cover rounded-md mb-2"/>
                <h3 className="text-lg font-semibold">{raffle.title}</h3>
                <p className="text-sm">{raffle.description}</p>
              </Link>
            </div>
          ))}
        </Slider>
      </div>

      {/* Categories */}
      {categories.map((category) => (
        <div key={category.name} className="mb-8 bg-gray-100 p-4 rounded-md relative">
          <h2 className="text-2xl font-semibold mb-4">{category.name}</h2>

          {category.raffles.length > 0 ? (
            <Slider
              {...settings} // pass in the settings object defined above
              className='mx-4 flex-nowrap'
              dots={false}
              infinite={true}
              speed={500}
              //slidesToShow={1}
              slidesToScroll={1}
              arrows={true}
              centerMode={false}
              initialSlide={0}
            >
              {category.raffles.map((raffle) => (
                <div key={raffle._id}>
                  <Link 
                    to={`/raffles/${raffle._id}`} 
                    className="block p-4 bg-white rounded-lg shadow-md w-64 mb-2">
                    <img src={raffle.imageUrls[0]} alt={raffle.title} className="w-64 h-40 object-cover rounded-md mb-2"/>
                    <h3 className="text-lg font-semibold">{raffle.title}</h3>
                    <p className="text-sm">{raffle.description}</p>
                  </Link>
                </div>
              ))}
            </Slider>
          ) : (
            <p className="text-gray-500">No raffles available for this Category</p>
          )}
        </div>
      ))}
    </div>
  );


};
