import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import Login from './Login'
// import Register from './Register';
import reportWebVitals from './reportWebVitals';
import './styles/tailwind.css';

const rootElement = document.getElementById('root');

const AppWithStrictMode = (
  process.env.NODE_ENV === 'development' ? 
  <React.StrictMode>
    <App />
  </React.StrictMode> : 
  <App />
);

const root = ReactDOM.createRoot(rootElement);
root.render(AppWithStrictMode);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
